import { TOTP } from 'otpauth'
import {
  Text,
  Input,
  VStack,
  Button,
  Image,
  Alert,
  Box,
  IconButton,
  HStack,
} from '@chakra-ui/react'
import React from 'react'
import { FaRegCopy } from 'react-icons/fa'

function App() {
  const [password, setPassword] = React.useState('')
  const handlePasswordChange = (event: {
    target: { value: React.SetStateAction<string> }
  }) => setPassword(event.target.value)
  const [error, setError] = React.useState('')
  const [token, setToken] = React.useState('∞')

  const getToken = () => {
    setError('')
    setToken('')
    if (!password) {
      setError('secret is required')
      return
    }

    const token = generateToken({ secret: password })
    setToken(token)
  }

  const handleEnter = (event: { key: string }) => {
    if (event.key === 'Enter') {
      getToken()
    }
  }

  const copyToken = () => navigator.clipboard.writeText(token)

  return (
    <VStack p={10}>
      <Box width={'50%'} p={10}>
        <Image src="logo-large.png" />
      </Box>

      {error && <Alert variant={'error'}>{error}</Alert>}

      <HStack p={10}>
        <Text aria-label="Token" fontSize={50} p={4} letterSpacing={8}>
          {token}
        </Text>
        {token !== '∞' ? (
          <IconButton
            aria-label={'copy'}
            icon={<FaRegCopy />}
            onClick={copyToken}
          />
        ) : (
          <></>
        )}
      </HStack>

      <Input
        placeholder="Secret value"
        aria-label="Password"
        width="50%"
        value={password}
        onChange={handlePasswordChange}
        type="password"
        onKeyUp={handleEnter}
      />
      <Button aria-label="Generate" onClick={getToken}>
        Generate
      </Button>
    </VStack>
  )
}

export default App

const generateToken = ({ secret }: { secret: string }) => {
  const totp = new TOTP({
    algorithm: 'SHA1',
    digits: 6,
    period: 30,
    secret,
  })
  const token = totp.generate()
  return token
}
